import React, { useState, useEffect } from "react";
import css from "./Login.module.css";
import arrowRightYellow from "@/assets/arrowRightYellow.svg";
import LoginForm from "@/src/components/Login/LoginForm/LoginForm";
import Link from "next/link";
import Router from "next/router";
import auth from "@/api/auth/auth";
import LoginLayout from "../../layout/LoginLayout";
import { preloadGlobalResources, redirectIfAuth } from "@/util/authUtils";
import { withRedux } from "@/util/reduxUtils";
import { useTranslation } from "@/util/helper";
import { getApiClient, getMiddlewareApiClient } from "@/api/util";
import { setKybStep } from "@/redux/kybStep";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setZendeskJwt } from "@/redux/zendesk";
import { getSafeDeep, parseErrors } from "@/util/state";

function Login({ dispatch }) {
    const { t } = useTranslation();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                // Get csrftoken
                await auth().getUser();
            } catch (e) {
                console.log('err', e);
            }
        })();
    }, []);

    const login = async () => {
        if (username == null || username == "") {
            setErrors({
                username: t('errors.requiredField'),
            })
            return
        }
        if (password == null || password == "") {
            setErrors({
                password: t('errors.requiredField'),
            })
            return
        }
        setIsLoading(true);
        try {
            await auth().login(username, password, rememberMe)
            window.location.replace("/")
        } catch (err) {
            if (getSafeDeep(err.response, "data.code") == 'accountLocked') {
                setErrors({
                    username: t('errors.accountLocked'),
                    password: undefined,
                })
            } else {
                setErrors({
                    username: t('login:incorrectEmail'),
                    password: t('login:incorrectPassword'),
                });
            }
            setIsLoading(false);
        }
    };

    return (
        <LoginLayout>
            <div className={css.title}>
                Dashboard
                <span className={css.dot}>.</span>
            </div>
            <div className={css.description}>
                {t('login:loginDescription')}
            </div>
            <LoginForm
                username={username}
                password={password}
                setUsername={setUsername}
                setPassword={setPassword}
                rememberMe={rememberMe}
                setRememberMe={setRememberMe}
                errors={errors}
                isLoading={isLoading}
                setErrors={setErrors}
                onSubmit={login}
            />
            <Link href="/forgot-password">
                <a className={css.forgotPassword}>
                    {t('login:forgotPassword')}
                </a>
            </Link>
            <div className={css.posQuestion}>
                {t('login:posQuestion')}
            </div>
            <div className={css.posRedirectWrapper}>
                <img src={arrowRightYellow} />
                <a href="https://pos.gocrypto.com" className={css.posLink}>
                    {t('login:posLink')}

                </a>
            </div>
        </LoginLayout>
    )
}

Login.getInitialProps = async (ctx) => {
    if (!(await redirectIfAuth(ctx))) return;
    await preloadGlobalResources(ctx);

    try {
        await auth(ctx).getUser();
    } catch (e) {

    }

    return {
        namespacesRequired: ["common", "login"],
    }
};

const mapStateToProps = (state) => ({
    zendeskJwt: state.zendeskJwt,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setZendeskJwt }, dispatch);
}

export default withRedux(connect(mapStateToProps, mapDispatchToProps)(Login));
